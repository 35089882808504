const { useReactComponent } = require('../../../../app/shared/componentLibraryService');
const { AssetPriceTicker } = require('../../react-components/AssetPriceTicker/AssetPriceTicker');

/**
 * Gets Asset Price Ticker component
 * @param {Object} assetTickerData Object containing topAssets, trendingCryptos and trendingNFTs
 * @returns Server side ready react component
 */

const getAssetPriceTicker = (assetTickerData) => useReactComponent(AssetPriceTicker, {
	assetTickerData,
});

module.exports = {
	getAssetPriceTicker,
};
