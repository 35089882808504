import { streamIndexObservable } from '../desktop/streamService';
import { serverData } from '../../shared/clientConfigService';

import { fireGAEvent } from '../../shared/tracking';

/**
 * Add tracking for the Best List Module
 */
function doTrackingForBestProductList(streamIndex) {
	const currentArticle = document.getElementById(`article-container-${streamIndex || 0}`);
	const articleHeadline = currentArticle.querySelector('.fs-headline')?.textContent || '';
	const bestListEmbed = currentArticle.querySelector('.best-list-embed');

	if (bestListEmbed) {
		const bestListProduct = bestListEmbed.querySelectorAll('.best-list-product');
		bestListProduct.forEach((element, index) => {
			const award = element.querySelector('.best-list-award-heading')?.textContent;
			const link = element?.querySelector('a');
			if (link) {
				const productLink = link.getAttribute('href');
				const trackingLabel = `ExternalBestList${index + 1} - ${award} - ${articleHeadline} - BuyLink:${productLink}`;
				link.setAttribute('data-ga-track', trackingLabel);
				fireGAEvent({
					eventAction: 'Click',
					eventLabel: `${trackingLabel}`,
				});
				// add tracking to the child elements of the link
				const linkChildren = link.querySelectorAll('*');
				linkChildren.forEach((child) => {
					child.setAttribute('data-ga-track', trackingLabel);
				});
			}
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	if (serverData.isForbesFinds) {
		doTrackingForBestProductList(0);
	}
});

streamIndexObservable.subscribe(doTrackingForBestProductList);
