import { getGamesPromo } from '../profile/react/gamesPromo';

/**
 * @param {Number} streamIndex
 * Hydrates the GamesPromo component after being rendered from the server.
 */
const hydrateGamesPromo = (streamIndex) => {
	const gamesPromoContainer = document.getElementById(`gamesPromo-${streamIndex}`);

	if (gamesPromoContainer) {
		getGamesPromo(streamIndex).hydrate(gamesPromoContainer);
	}
};

export default hydrateGamesPromo;
