import { fireGAEvent } from '../../shared/tracking';
import { getAbParam } from '../../shared/adblock';
import setVideoKeyValues from './setVideoKeyValues';

const RIGHT_RAIL_PLAYER_TYPE = 'right-rail';
const IN_BODY_BREAKING_MOBILE_PLAYER_TYPE = 'in-body-breaking-mobile';
const IN_BODY_STANDARD_MOBILE_PLAYER_TYPE = 'in-body-standard-mobile';

const isAdLight = () => !!window.forbes?.['simple-site']?.isAdLight;

function isBrandedContent(tracking) {
	const { blogType } = tracking;
	return blogType === 'ad' || blogType === 'connoisseur' || blogType === 'insights';
}

/**
 * Loads the player when the videos for it are ready
 *
 * @param {Element} videoPlaceholder
 * @param {Array} relativeVideos
 * @param {Boolean} shouldSetKeyValues
 * @param {String} fullAdZone
 * @param {Boolean} disableAds
 * @param {String} debugLabel
 * @param {String} playerType
 * @param {Function} onPlayerMount Called when the actual fbs-video is mounted in the dom
 */
function onRightRailVideosReady({
	videoPlaceholder,
	relativeVideos,
	shouldSetKeyValues,
	fullAdZone = '',
	disableAds,
	debugLabel,
	playerType,
	onPlayerMount,
}) {
	fireGAEvent({
		eventCategory: 'Connatix Video Player',
		eventAction: 'MountingRightRailPlayer',
		eventLabel: debugLabel,
	});

	let videoPlayer = videoPlaceholder.querySelector('fbs-video');
	if (videoPlayer) {
		// player already exists - just play and float it in that case
		videoPlayer.play();
		if (playerType === RIGHT_RAIL_PLAYER_TYPE) {
			videoPlayer.float();
		}
		return;
	}

	videoPlayer = document.createElement('fbs-video');
	if (fullAdZone) {
		videoPlayer.adUnitPath = fullAdZone;
	}

	videoPlayer.role = 'presentation';
	videoPlayer.muted = 'true'; // default is muted
	videoPlayer.keyValueString = '';

	const video = relativeVideos[0];

	setVideoKeyValues({
		videoPlayer,
		videoKeyValues: shouldSetKeyValues ? video.keyValues : '',
		isRightRailVideo: true,
	});

	videoPlayer.videoIdList = relativeVideos.map((v) => v.videoId).slice(0, 3);
	videoPlayer.playerId = video.playerId;
	videoPlayer.playerType = playerType;
	videoPlayer.autoplay = !getAbParam();

	if (playerType === RIGHT_RAIL_PLAYER_TYPE) {
		// Right rail player should not have any thumbnails/poster, to avoid being flagged as LCP on the page
		videoPlayer.setAttribute('hide-thumbnail', 'true');
		videoPlayer.floatingEnabled = !isAdLight();

		if (window.innerWidth >= 1230) {
			videoPlayer.aspectRatio = '4/3';
		}
	}

	videoPlayer.adsDisabled = disableAds || getAbParam();

	if (playerType !== RIGHT_RAIL_PLAYER_TYPE && videoPlayer.autoplay) {
		// right rail player plays automatically, while all others play when in view
		videoPlayer.autoplayInView = true;
	}

	videoPlaceholder.appendChild(videoPlayer);
	videoPlaceholder.classList.remove('inactive');

	if (onPlayerMount) {
		onPlayerMount(videoPlayer);
	}
}

/**
 * Look in the article container for placeholders of our known accompanying player types, in order of occurrence (in theory
 * only one of those should exist on the page), and return an object with playerType and videoPlaceholder.
 *
 * @param {Element} articleContainer The article container.
 * @return {{playerType: string, videoPlaceholder: Element | null} | undefined}
 */
function findFirstAccompanyingVideoPlaceholder(articleContainer) {
	const placeHolders = [
		{
			playerType: RIGHT_RAIL_PLAYER_TYPE,
			videoPlaceholder: articleContainer.querySelector('.right-rail .video-player'),
		},
		{
			playerType: IN_BODY_BREAKING_MOBILE_PLAYER_TYPE,
			videoPlaceholder: articleContainer.querySelector('.video-placeholder-breaking-mobile'),
		},
		{
			playerType: IN_BODY_STANDARD_MOBILE_PLAYER_TYPE,
			videoPlaceholder: articleContainer.querySelector('.video-placeholder-standard-mobile'),
		},
	];

	return placeHolders.find(({ videoPlaceholder }) => Boolean(videoPlaceholder));
}

/**
 * Initializing the video load - called when a new article is being loaded
 * @param {Object} serverData The article serverData.
 * @param {number} streamIndex The stream index of the article.
 * @param {Function} onPlayerMount Called when the actual fbs-video is mounted in the dom
 *
 * @return {boolean} True, if an accompanying player exists, false otherwise
 */
function initAccompanyingVideoPlayers(serverData, streamIndex, onPlayerMount = () => undefined) {
	const articleContainer = document.getElementById(`article-stream-${streamIndex}`);
	const placeHolder = findFirstAccompanyingVideoPlaceholder(articleContainer);

	if (!placeHolder) {
		// no accompanying player - return false so parent functionality can decide if to check for in-body videos
		return false;
	}

	const {
		playerType,
		videoPlaceholder,
	} = placeHolder;

	fireGAEvent({
		eventCategory: 'Connatix Video Player',
		eventAction: 'InitRightRailPlayer',
		eventLabel: '',
	});

	/* eslint-disable no-underscore-dangle */
	const fullAdZone = window.fbsads._config.ad_unit_path;

	const relativeVideosReady = (relativeVideos = [], disableAds = false, debugLabel) => onRightRailVideosReady({
		videoPlaceholder,
		relativeVideos,
		shouldSetKeyValues: !isBrandedContent(serverData.tracking),
		fullAdZone,
		disableAds,
		debugLabel,
		playerType,
		onPlayerMount,
	});

	// We can't put campaign videos in front of special-slotted / swimlaned articles, so we just set the relative video.
	if (!serverData.swimLane && !serverData.specialSlot && !window.isCampaignVideoAlreadyAdded
		&& window.fbsCampaignService && !serverData.isE2E) {
		window.fbsCampaignService.getGAMCampaignVideo()
			.then(({
				id,
				showAds,
			}) => {
				if (id) {
					window.isCampaignVideoAlreadyAdded = true;

					const campaignVideo = {
						playerId: 'S1EBSbDn',
						title: '',
						videoId: id,
						keyValues: '',
						adsDisabled: !showAds,
					};

					// prevent a potential error thrown in relativeVideosReady to trigger it twice in the promise error handler
					try {
						relativeVideosReady([
							campaignVideo,
							...serverData.relativeVideos,
						], !showAds, `SCH-${id}`);
					} catch (e) {
						relativeVideosReady(serverData.relativeVideos, false, 'SCHERROR-OTHER');
					}
					return;
				}

				relativeVideosReady(serverData.relativeVideos, false, 'SCHERROR-OTHER');
			})
			.catch((e) => {
				relativeVideosReady(serverData.relativeVideos, false, e.label);
			});
	} else {
		relativeVideosReady(serverData.relativeVideos, false, 'NOSCH');
	}

	return true;
}

export default initAccompanyingVideoPlayers;
