import { getAssetPriceTicker } from '../profile/react/assetPriceTicker';

/**
 * Hydrates the AssetPriceTicker component after being rendered from the server.
 */
const hydrateAssetPriceTicker = () => {
	const assetPriceTickerContainer = document.getElementById('assetticker__container--fda');
	const { assetTickerData } = window.forbes['simple-site'];

	if (assetPriceTickerContainer
		&& assetTickerData?.topAssets?.length
		&& assetTickerData?.trendingCryptos?.length
		&& assetTickerData?.trendingNFTs?.length
	) {
		getAssetPriceTicker(assetTickerData).hydrate(assetPriceTickerContainer);
	}
};

export default hydrateAssetPriceTicker;
