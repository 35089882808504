const { createRoot } = require('react-dom/client');
const React = require('react');
const { PropTypes } = require('prop-types');
const { adelaideWhite } = require('@forbes/fbs-icons');
const { default: HighlightModal } = require('@forbes/fbs-components/dist/cjs/components/HighlightModal/HighlightModal');
const { reactComponentsTracking } = require('../../shared/react-tracking');
const { useState, useEffect, useCallback } = React;

/**
 * Returns the tracking data
 * @param {string} action the action name
 * @param {string} label the tracking label
 * @returns Formatted tracking props
 */
const getTrackingProps = (action, label) => ({
	track: reactComponentsTracking,
	category: 'Highlights',
	action,
	label,
});

/**
 * The Highlights component, combines the AI.highlights with the corresponding wrapper.
 * @param {string} childData Child string (contains html tags).
 * @param {Object} data Highlights object data.
 * @param {string} highlightIndex Highlights object data.
 * @param {boolean} showIcon Boolean to show ai icon.
 * @returns The Highlights JSX component
 */
const Highlights = ({ childData, data, highlightIndex, showIcon }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const { bullets = [], text = "", topics = [] } = data;

	topics.forEach((topic) => topic.trackingProps = getTrackingProps(
		'Click',
		`ai-article-highlight-{${highlightIndex}}-modal-article-{${topic.name}}-click`
	));

	const onModalOpen = () => {
		setModalOpen(true);
		reactComponentsTracking('Highlights', 'Click', `ai-article-highlight-{${highlightIndex}}-click`);
	}

	const handleMouseEnter = () => reactComponentsTracking('Highlights', 'Hover', `ai-article-highlight-{${highlightIndex}}-hover`);

	const onClose = useCallback(() => {
		reactComponentsTracking('Highlights', 'Click', `ai-article-highlight-{${highlightIndex}}-dismiss-click`);
		setModalOpen(false);
	}, [setModalOpen])

	return (
		<>
			<span onMouseEnter={handleMouseEnter} onClick={onModalOpen} className='highlightText' >
				<span dangerouslySetInnerHTML={{ __html: childData }} />
				{showIcon && <span className='highlightIcon' dangerouslySetInnerHTML={{ __html: adelaideWhite }} />}
			</span>
			{isModalOpen &&
				<HighlightModal
					contentBullets={bullets}
					contentText={text}
					footerText={'Powered by Adelaide, Forbes AI'}
					onClose={onClose}
					topics={topics.slice(0, 3)}
				/>
			}
		</>
	)
}

/**
 * It finds all the elements inside the article body, having class `fbs-highlight`, and then styles them as the highlights.
 * @param {Object} genAIData Article.ai field value from the Api Response.
 * @returns A JSX component. This component is used as an anchor.
 */
const HighlightsWrapper = ({ genAIData }) => {
	const { highlights: { highlights = [], naturalId } = {} } = genAIData;

	useEffect(() => {
		const highlightedSections = document.getElementsByClassName(`fbs-highlight-${naturalId}`);
		if (!highlightedSections?.length) return;

		highlightedSections.forEach((section) => {
			const dataId = section.getAttribute("data-id");

			if (!dataId) return;
			const matchedHighlight = highlights[parseInt(dataId)];

			if (matchedHighlight) {
				const iconMark = section.querySelector('[show-icon="true"]');
				createRoot(section).render(<Highlights childData={section.innerHTML} data={matchedHighlight} highlightIndex={parseInt(dataId) + 1} showIcon={!!iconMark} />);
			}
		})
	}, []);

	return <span id="highlight-wrapper"></span>;
}


HighlightsWrapper.propTypes = {
	genAIData: PropTypes.object,
};

Highlights.propTypes = {
	childData: PropTypes.string,
	data: PropTypes.object,
	highlightIndex: PropTypes.number,
	showIcon: PropTypes.bool,
};

module.exports = {
	HighlightsWrapper,
	Highlights,
};
