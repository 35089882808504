const gamesList = [
	{
		name: 'Frase By Forbes',
		type: 'frase',
		description: 'Unscramble The Anagram To Reveal The Phrase',
		icon: 'https://images.forbes.com/fbs-games-assets/frase.png',
		src: 'https://www.forbes.com/frase/',
		isFeatured: true,
	},
	{
		name: 'Pinpoint By Linkedin',
		type: 'pinpoint',
		description: 'Guess The Category',
		icon: 'https://images.forbes.com/fbs-games-assets/pinpoint.png',
		src: 'https://www.forbes.com/games/pinpoint',
		isFeatured: false,
	},
	{
		name: 'Queens By Linkedin',
		type: 'queens',
		description: 'Crown Each Region',
		icon: 'https://images.forbes.com/fbs-games-assets/queens.png',
		src: 'https://www.forbes.com/games/queens',
		isFeatured: false,
	},
	{
		name: 'Crossclimb By Linkedin',
		type: 'crossclimb',
		description: 'Unlock A Trivia Ladder',
		icon: 'https://images.forbes.com/fbs-games-assets/crossclimb.png',
		src: 'https://www.forbes.com/games/crossclimb',
		isFeatured: false,
	},
];

module.exports = gamesList;
