/**
 * Handles setting the key-value-string for the video element.
 * @param {Element} videoPlayer The video element
 * @param {String} videoKeyValues The key-values for the video element.
 * @param {Boolean} isRightRailVideo True, if this is a right rail video player
 */
function setVideoKeyValues({
	videoPlayer,
	videoKeyValues = '',
	isRightRailVideo = false,
}) {
	const filteredKV = videoKeyValues.split('&').filter((keyStr) => keyStr.indexOf('displaysection') === -1 && keyStr.indexOf('displaychannel') === -1).join('&');
	const largeArticleRightRailTrigger = 1230;
	let sidebarWidth = '';
	let position = filteredKV ? '&pos=' : 'pos=';

	if (isRightRailVideo) {
		position += 'vid-lr';
		sidebarWidth = window.innerWidth >= largeArticleRightRailTrigger ? '&vw=400plus' : '&vw=less400';
	} else {
		position += 'vid-iab';
	}
	const keyValueString = `${filteredKV}${position}${sidebarWidth}`;

	videoPlayer.setAttribute('key-value-string', keyValueString);
}

export default setVideoKeyValues;
