const {
	useReactComponent,
} = require('../../../../app/shared/componentLibraryService');
const { GamesPromo } = require('../../react-components/GamesPromo/GamesPromo');

/**
 * @param {Number} streamIndex
 * @returns Server side ready react component
 */

const getGamesPromo = (streamIndex) => useReactComponent(GamesPromo, { streamIndex });

module.exports = {
	getGamesPromo,
};
