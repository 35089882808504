const React = require('react');
const { PropTypes } = require('prop-types');

const { default: CardCrypto } = require('@forbes/fbs-components/dist/cjs/components/CardCrypto/CardCrypto');
const { default: AnimatedCarousel } = require('@forbes/fbs-components/dist/cjs/components/AnimatedCarousel/AnimatedCarousel');
const { default: HeaderBox } = require('@forbes/fbs-components/dist/cjs/components/HeaderBox/HeaderBox');

const { reactComponentsTracking } = require('../../shared/react-tracking');

/**
 * Returns the tracking data
 * @param {string} action the action name
 * @param {string} label the tracking label
 * @returns Formatted top assets
 */
const getTrackingProps = (action, label, category = 'Forbes Digital Assets') => ({
	track: reactComponentsTracking,
	category,
	action,
	label,
});

const AssetPriceTicker = ({ assetTickerData }) => {
	const { topAssets = [], trendingCryptos = [], trendingNFTs = [] } = assetTickerData;
	const [tickerData, setTickerData] = React.useState(trendingCryptos);

	const headerData = [

		{
			id: 1,
			isDefault: true,
			onSelect: () => setTickerData(trendingCryptos),
			optionTrackingProps: getTrackingProps('Click', 'fda-article-ticker-category-click{TrendingCrypto}'),
			shortTitle: 'Viral',
			title: 'Trending Crypto',
		}, {
			id: 2,
			onSelect: () => setTickerData(topAssets),
			optionTrackingProps: getTrackingProps('Click', 'fda-article-ticker-category-click{Top10Crypto}'),
			shortTitle: 'Top 10',
			title: 'Top 10 Crypto',
		},
		{
			id: 3,
			onSelect: () => setTickerData(trendingNFTs),
			optionTrackingProps: getTrackingProps('Click', 'fda-article-ticker-category-click{TrendingNFTs}'),
			shortTitle: 'NFTs',
			title: 'Trending NFTs',
		},
	];

	return (
		!!tickerData.length && (
			<div className="priceTickerContainer">
				<HeaderBox
					className="headerBox"
					data={headerData}
				/>
				<AnimatedCarousel
					className="assetPriceTicker"
					pauseOnHover
					trackingProps={getTrackingProps('Pause', 'fda-article-ticker-horizontalscroll-pause')}
				>
					{[...tickerData, ...tickerData].map((assetData, index) => (
						<CardCrypto
							className="tickerCard"
							data={{ ...assetData }}
							// eslint-disable-next-line react/no-array-index-key
							key={`crypto-card-${index}`}
							noChart
							target="_self"
						/>
					))}
				</AnimatedCarousel>
			</div>
		)
	);
};

AssetPriceTicker.propTypes = {
	assetTickerData: PropTypes.object.isRequired,
};

module.exports = {
	AssetPriceTicker,
};
