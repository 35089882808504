// Handles right rail video and top in-body video floating and targeting keys logic.
import { streamObservable, streamIndexObservable } from './streamService';
import { handleInread } from './inreadAd';
import initAccompanyingVideoPlayers from '../shared/accompanyingVideoPlayer';
import setVideoKeyValues from '../shared/setVideoKeyValues';

// the current fbs-video component that has floating functionality
let currentVideoPlayer;

let currentStreamIndex = 0;

const isAdLight = () => !!window.forbes?.['simple-site']?.isAdLight;

/**
 * While the Top AD is in sticky mode, the player should not be
 * @param {Boolean} isTopAdSticky boolean value to determine whether the ad is floating
 */
function videoTopAdStickyStateChange(isTopAdSticky) {
	if (!currentVideoPlayer?.floatingEnabled) {
		return;
	}

	if (isTopAdSticky) {
		currentVideoPlayer.unFloat('topAdIsSticky');
		return;
	}

	currentVideoPlayer.float('topAdIsSticky');
}

/**
 * Check if blogType is a paidContent type that doesn't get normal ad treatments.
 * @param {Object} tracking
 * @return {Boolean}
 */
function isBrandedContent(tracking) {
	const { blogType } = tracking;
	return blogType === 'ad' || blogType === 'connoisseur' || blogType === 'insights';
}

/**
 * This is called when the user switches between already loaded articles in the stream, and also after the video
 * is mounted for the first time, if the stream index in the same (e.g. for stream position 0)
 *
 * @param {Number} streamIndex The article index in the stream.
 */
function switchVideoPlayer(streamIndex) {
	currentStreamIndex = streamIndex;
	if (currentVideoPlayer) {
		currentVideoPlayer.pause();
		currentVideoPlayer.unFloat();
		currentVideoPlayer = null;
	}

	const currentArticleContainer = document.getElementById(`article-stream-${streamIndex}`);
	const rightRailPlaceholder = currentArticleContainer.querySelector('.right-rail .video-player');

	if (rightRailPlaceholder) {
		currentVideoPlayer = rightRailPlaceholder.querySelector('fbs-video[autoplay]');
		return;
	}

	// select the top in-body player - it is the first and only fbs-video that has autoplay inside the body
	currentVideoPlayer = currentArticleContainer.querySelector('.article-body fbs-video[autoplay]');
}

/**
 * Two article types are checked here and handled depending on parameters.
 * @param {Element} articleContainer Location of video placement.
 * @param {Object} serverData The article serverData.
 */
function handleArticleWithoutRightRailVideo(articleContainer, serverData) {
	// check for top in-body player - it always auto-plays
	const allVideoPlayers = articleContainer.querySelectorAll('fbs-video') || [];

	allVideoPlayers.forEach((player) => {
		if (player.hasAttribute('autoplay') && player.getAttribute('autoplay') !== 'false') {
			// article top in-body player
			// Article with no top-video
			if (!isBrandedContent(serverData.tracking)) {
				setVideoKeyValues({
					videoPlayer: player,
					videoKeyValues: player.getAttribute('key-value-string') || '',
					isRightRailVideo: false,
				});
			}

			// if this is already the current article, switch the current videoPlayer to it
			if (currentStreamIndex === serverData.streamIndex) {
				switchVideoPlayer(serverData.streamIndex);
			}

			player.playerType = 'top-in-body';
			player.floatingEnabled = !isAdLight();

			return;
		}

		player.playerType = 'in-body';
	});

	handleInread(serverData.streamIndex);
}

/**
 * Initializing the video load - called when a new article is being loaded
 * @param {Object} serverData The article serverData.
 */
function initArticleVideoPlayers(serverData) {
	const hasAccompanyingPlayer = initAccompanyingVideoPlayers(serverData, serverData.streamIndex, () => {
		// if this is already the current article, switch the current videoPlayer to it
		if (serverData.streamIndex === currentStreamIndex) {
			switchVideoPlayer(serverData.streamIndex);
			handleInread(serverData.streamIndex);
		}
	});

	if (!hasAccompanyingPlayer) {
		const articleContainer = document.getElementById(`article-stream-${serverData.streamIndex}`);
		handleArticleWithoutRightRailVideo(articleContainer, serverData);
	}
}

/**
 * Adds a class to the body if meter ribbon exists
 */
function addMeterRibbonClass() {
	const meterRibbon = document.querySelector('.ribbon');
	if (meterRibbon) {
		document.body.classList?.add('meter-ribbon-visible');
	}
}
document.addEventListener('zephr.browserDecisionsFinished', addMeterRibbonClass);

// when a new article is loaded (including initial page load)
streamObservable.subscribe(initArticleVideoPlayers);

// when the user actually navigates to a new article (including initial)
streamIndexObservable.subscribe(switchVideoPlayer);

export default videoTopAdStickyStateChange;
