const React = require('react');
const { PropTypes } = require('prop-types');
// eslint-disable-next-line custom-rules/no-duplicate-requires
const { useRef, useEffect } = require('react');
const gamesList = require('./data/gamesPromoData');
const { primeInviewEvent } = require('../../shared/trackingUtils');
const { reactComponentsTracking } = require('../../shared/react-tracking');

const handleClick = (e, type, url) => {
	e.preventDefault();
	
	reactComponentsTracking(
		'template area interaction',
		'click',
		`games-recirc-${type}`,
	);

	window.location.href = url;
}

const FeaturedGame = () => (
	<span>
		<span className="gamesPromoFraseGreenText">FEATURED</span>
		<span className="separator">{' | '}</span>
	</span>
);

const GameCard = ({
	name, type, description, icon, src, isFeatured,
}) => {
	const formatGameName = (gameName, gameType, gameIsFeatured) => {
		if (gameType === 'frase') {
			return (
				<>
					{gameIsFeatured && <FeaturedGame />}
					<span className="gamesPromoName gamesPromoNameFrase">
						<span className="gamesPromoFraseBy">
							Frase By
						</span>
						<span className="gamesPromoFraseForbes">
							Forbes
							<span className="gamesPromoFraseTrademark">
								&#8482;
							</span>
						</span>
					</span>
				</>
			);
		}

		return <span className="gamesPromoName gamesPromoNameRest">{gameName}</span>;
	};

	return (
		<div className="gamesPromoGameContainer">
			<a
				className="gamesPromoGame"
				href={src}
				onClick={(e) => handleClick(e, type, src)}
			>
				<img
					src={icon}
					alt={type}
					height={80}
					width={80}
					className="gamesPromoLogo"
				/>

				<div className="gamesPromoContent">
					<div className="gamesPromoNameContainer">
						<p>
							{formatGameName(name, type, isFeatured)}
						</p>
					</div>
					<div className="gamesPromoDescriptionContainer">
						<p className="gamesPromoDescription">{description}</p>
					</div>
					<span className="gamesPromoLinkButton">
						Play Now
					</span>
				</div>
			</a>
		</div>
	);
};

GameCard.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired,
	isFeatured: PropTypes.bool,
};

const GamesPromo = ({ streamIndex }) => {
	const gamesPromoBlockRef = useRef(null);

	useEffect(() => {
		if (!gamesPromoBlockRef.current) return;

		primeInviewEvent(
			gamesPromoBlockRef.current,
			'games-recirc',
			1,
			'in-view',
		);
	}, []);

	return (
		<div id={`gamesPromo-${streamIndex}`}>
			<div className='gamesPromoContainer' ref={gamesPromoBlockRef}>
				<div className="gamesPromoTitleContainer">
					<span className="gamesPromoTitle">
						Play Puzzles & Games on Forbes
					</span>
				</div>

				<div className="gamesPromoGamesListContainer">
					{gamesList.map((game) => (
						<GameCard key={game.type} {...game} />
					))}
				</div>
			</div>
		</div>
	);
};

GamesPromo.propTypes = {
	streamIndex: PropTypes.Number,
};

module.exports = {
	GamesPromo,
};
