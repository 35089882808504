/**
 * Checks if given element is at all visible in user's viewport,
 * or is on top of user's viewport
 * @param {Element} el DOM element
 * */
// suppressing since this file might be used for more functions in future
// eslint-disable-next-line import/prefer-default-export
export function isElementInViewportOrAboveUser(el) {
	const rect = el.getBoundingClientRect();

	return rect.right > 0
		&& rect.left < (window.innerWidth || document.documentElement.clientWidth)
		&& rect.top < (window.innerHeight || document.documentElement.clientHeight);
}
