import React from 'react';
import { CardStream } from '@forbes/fbs-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { fireGAEvent } from '../../shared/trackingUtils';

/**
 * Fires a google analytics event
 * @param {String} label event label
 */
const triggerGAEvent = (label, value) => {
	fireGAEvent({
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: label,
		eventValue: value,
	});
};

const ThreeCardStream = ({ data, isSmallDevice, className }) => (
	<div className={cx('three-card-stream', className)}>
		{data.map(({
			title, index, uri, image, authors, published_date: timestamp,
		}) => (
			<CardStream
				className="card-stream-container"
				key={`${title}-${index}`}
				articleUrl={uri}
				title={title}
				timestamp={new Date(timestamp).getTime()}
				target="_self"
				description=""
				imageSrc={isSmallDevice ? null : image}
				onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
				isLazyLoading
				byLineProps={{
					author: [
						{
							link: authors[0]?.link,
							name: authors[0]?.name,
							type: authors[0]?.type,
						},
					],
					displayBy: true,
					size: 'small',
					className: 'custom-stream',
				}}
			/>
		))}
	</div>
);

ThreeCardStream.propTypes = {
	data: PropTypes.array.isRequired,
	isSmallDevice: PropTypes.bool,
	className: PropTypes.string,
};

export default ThreeCardStream;
