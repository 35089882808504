import { tracking } from './clientConfigService';
import { checkArticleIsPremiumOrMasthead } from './stream-share';
import Observable from './Observable';
import { isElementInViewportOrAboveUser } from './sharedAdUtils';

const progressiveAds = [];
export const adObservable = new Observable();

const paddingTopBottom = 24;
const isPremium = (tracking.templateType === 'premium');

/**
 * Sets if the ad is a blocked ad or not
 * @param {number[]} sizes The ad sizes from Google Ad Manager
 * @returns {boolean} True if serving blocked ad
 */
const isBlockedAd = (sizes = []) => ((Array.isArray(sizes) ? sizes : [])[0] === 1 && sizes[1] === 1);
/**
 * checks if the ad is topx and in a preimum or masthead type for the 970x600 topx ad to load earlier
 * @param {FbsAd} ad the ad to check
 * @returns {boolean} is the current page is premium or masthead
 */
function checkIfAdIsTopxAndInPremiumOrMasthead(ad) {
	return ad.getAttribute('position') === 'topx' && checkArticleIsPremiumOrMasthead();
}

/**
 * Check position of ad to see if it is close enough to load
 * @param {FbsAd} ad The ad to check
 * @return {boolean} is the ad in view (or close enough - 25% of window height away) that it should be loaded
 */
function progressiveAdShouldLoad(ad) {
	// TODO(iingato): Check that the ad slot is available at this point.
	const PROGRESSIVE_LOAD_PERCENTAGE = ad.getAttribute('data-double-progressive')
	|| checkIfAdIsTopxAndInPremiumOrMasthead(ad) || ad.getAttribute('position').includes('nativeinbody') ? 1 : 0.25;
	const { bottom } = ad.getBoundingClientRect();
	const editoolsOpen = document.documentElement.classList.contains('et-on');

	return !editoolsOpen && ad.div && (bottom - window.innerHeight < (window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE));
}

/**
 * Displays ad that is in view or adds to progressiveAds collection to
 * be displayed on scroll
 * @param {FbsAd} ad The ad to insert
 */
export function insertProgressiveAd(ad) {
	if (progressiveAdShouldLoad(ad) && ad.div) {
		if (typeof ad.display !== 'function') {
			// Since we're rendering these ads server side,
			// there is sometimes a timing issue where if the ad is progressive
			// but is in the viewport and should load
			// the ad hasn't finished bootstrapping.
			const bootstrapCallback = () => {
				ad.display();
				ad.removeEventListener('bootstrapped', bootstrapCallback);
			};

			ad.addEventListener('bootstrapped', bootstrapCallback);
		} else {
			ad.display();
		}
	} else if (progressiveAds.indexOf(ad) < 0) {
		progressiveAds.push(ad);
	}
}

/**
 * Handler for window.scroll event to check if any progressive ads are ready to display
 */
export function progressiveAdScrollHandler() {
	progressiveAds.forEach((ad) => {
		if (progressiveAdShouldLoad(ad)) {
			ad.display();
			progressiveAds.splice(progressiveAds.indexOf(ad), 1);

			if (ad.hasAttribute('data-double-progressive') && progressiveAds.length) {
				progressiveAds[0].display();
				progressiveAds.shift();
			}
		}
	});
}

// Add tabIndex attribute to ad iframe to skip the ad by tab key - Accessibility
export function addTabindex(ad, position) {
	if (position === 'ntv-home' || position === 'ntv-deskchannel') {
		return;
	}
	let counter = 0;
	const iframeInterval = setInterval(() => {
		const adiframe = ad.querySelector('iframe');
		let modified = false;
		if (adiframe && !adiframe.hasAttribute('tabindex')) {
			adiframe.setAttribute('tabindex', '-1');
			modified = true;
		}
		if (!modified && (adiframe || counter++ < 100)) {
			clearInterval(iframeInterval);
		}
	}, 100);
}

/**
 * Handles logic for spon-logo ad onRender event
 * @param {Event} event
 */
function sponLogoRenderCallback(event) {
	const { detail: { size = [], isEmpty = false } = {} } = event;
	const modalContent = document.querySelector('.modal__content');

	if (modalContent) {
		if (!isEmpty && size && size[0] && size[0] !== 1) {
			const sponsor = modalContent.querySelector('.sponsor');
			if (sponsor) {
				sponsor.classList.add('sponsor--loaded');
			}
			modalContent.classList.add('modal__content--has-sponsor');
		} else {
			modalContent.classList.remove('modal__content--has-sponsor');
		}
	}
}

/**
 * Handles the logic in creating the X button above the fluid ad
 * @param {HTMLElement} adContainer the ad container
 * @param {HTMLElement} footerContainer the footer html element
 * @param {String} footerMobileClass the mobile footer class
 * @returns {HTMLElement} The X button to be appended to the ad container
 */
function createFluidAdButton(adContainer, footerContainer, footerMobileClass) {
	const adClose = document.createElement('button');
	const adCloseX = document.createElement('span');

	adCloseX.innerHTML = 'x';
	adCloseX.classList.add('close');
	adClose.append(adCloseX);
	adClose.classList.add('fbs-ad--mobile-close');
	adClose.onclick = () => {
		adContainer?.classList.add('fbs-ad--mobile-hidden');
		footerContainer?.classList.remove(footerMobileClass, `${footerMobileClass}-fluid`);
		adClose.classList.remove('show');
	};

	return adClose;
}

/**
 * Handles the fluid ad container changes whenevr a fluid ad is shown on the page
 * @param {HTMLElement} ad the sticky mobile ad element
 * @param {String} footerMobileClass the mobile footer class
 * @param {String} fbsAdMobileClass the ad mobile class
 * @param {HTMLElement} footerContainer the footer html element
 * @param {HTMLElement} closeButton the close button html element
 */
function handleFluidAd({
	ad,
	footerMobileClass,
	fbsAdMobileClass,
	footerContainer,
	closeButton,
}) {
	const adContainer = document.querySelector(`.${fbsAdMobileClass}-wrapper`);
	ad?.classList.add(`${fbsAdMobileClass}-fluid`);
	footerContainer?.classList.add(`${footerMobileClass}-fluid`);

	if (!closeButton) {
		const adClose = createFluidAdButton(adContainer, footerContainer, footerMobileClass);
		adContainer?.append(adClose);

		setTimeout(() => {
			adClose.classList.add('show');
		}, 5000);
	}
}

/**
 * Handles logic for top ad onRender event
 * @param {Event} event
 */
function topAdRenderCallback(event) {
	const { detail: { size = [], isEmpty = false } = {} } = event;
	const adWrapper = document.querySelector('.fbs-ad--top-wrapper');

	if (!adWrapper) return;

	const parentElementId = adWrapper.parentElement.id;
	const ad = document.querySelector('fbs-ad[position="top"]');
	const isArticleAd = parentElementId.match(/article-container-\d+/);
	const isProfileListuserAd = adWrapper.parentElement.classList.contains('listuser-leadspace__hero');
	// no ad targeted or empty ad received
	if (isEmpty || size.every((pos) => pos === 1)) {
		adWrapper.style.height = 0;

		return;
	}

	// takeover ad
	if (size[0] === 7) adWrapper.classList.add('fbs-ad--top-wrapper--takeover');

	// fluid ad
	if (!isPremium && !isProfileListuserAd && size.every((pos) => pos === 0)) {
		const initialAdHeight = 134;

		ad?.classList.add('fbs-ad--fluid-top');
		if (!adWrapper.classList.contains('contributor-leaderboard-ad') && !isArticleAd) {
			adWrapper.classList.add('fbs-ad--top-wrapper-with-label');
		}

		const interval = setInterval(() => {
			if (ad.clientHeight !== 0 && ad.clientHeight !== initialAdHeight) {
				adObservable.notify({
					height: ad.clientHeight,
					isFluidAd: true,
					size,
				});
				clearInterval(interval);
			}
		}, 100);
	}

	// regular ads
	if (!isPremium && !isProfileListuserAd && size.every((pos) => (pos !== 0 && pos !== 1))) {
		if (adWrapper && !adWrapper.classList.contains('contributor-leaderboard-ad') && size[0] !== 7 && !isArticleAd) {
			adWrapper.classList.add('fbs-ad--top-wrapper-with-label');
		}
		adObservable.notify({
			height: size[1] + paddingTopBottom,
			isFluidAd: false,
			size,
		});
	}
}

/**
 * Handles the normal ad container changes whenevr one is shown on the page
 * @param {HTMLElement} ad the sticky mobile ad element
 * @param {String} footerMobileClass the mobile footer class
 * @param {String} fbsAdMobileClass the ad mobile class
 * @param {HTMLElement} footerContainer the footer html element
 * @param {HTMLElement} closeButton the close button html element
 */
function handleNormalAd({
	ad,
	footerMobileClass,
	fbsAdMobileClass,
	footerContainer,
	closeButton,
}) {
	if (closeButton) {
		ad?.classList.remove(`${fbsAdMobileClass}-fluid`);
		footerContainer?.classList.remove(`${footerMobileClass}-fluid`);
		closeButton.remove();
	}

	ad?.classList.add(fbsAdMobileClass);
}

/**
 * Handles logic for native inbody ad onRender event
 * @param {Event} event
 */
export function nativeInbodyAdHandler(event) {
	const { detail: { isEmpty = false, slot } = {} } = event;
	const ad = document.getElementById(slot.getSlotElementId());
	const wrapper = ad?.parentElement?.parentElement;
	if (!wrapper || isElementInViewportOrAboveUser(wrapper)) return;
	if (isEmpty) {
		wrapper.classList.add('hidden');
	} else {
		wrapper.classList.remove('hidden');
	}
}

/**
 * Handles logic for mobile sticky ad onRender event
 * @param {Event} event
 */
export function mobileStickyRenderCallback(event) {
	const { detail: { size = [], isEmpty = false } = {} } = event;
	const adWrapper = document.querySelector('.fbs-ad--top-wrapper');
	const ad = document.querySelector('fbs-ad[position="mobile"]');

	if (isEmpty) {
		return;
	}

	if (size[0] !== 7) {
		const footerContainer = document.querySelector('.footer');
		const footerMobileClass = 'footer--mobile';
		const fbsAdMobileClass = 'fbs-ad--mobile';
		const closeButton = document.querySelector(`.${fbsAdMobileClass}-close`);

		if (footerContainer) {
			footerContainer.classList.add(footerMobileClass);
		}

		if (size[1] === 0) {
			handleFluidAd({
				ad,
				footerMobileClass,
				fbsAdMobileClass,
				footerContainer,
				closeButton,
			});
		} else {
			handleNormalAd({
				ad,
				footerMobileClass,
				fbsAdMobileClass,
				footerContainer,
				closeButton,
			});
		}
	} else if (size[0] === 7) {
		if (adWrapper) {
			adWrapper.classList.add('fbs-ad--top-wrapper--takeover');
		}
	}
}

/**
 * Handles logic for moreon ad onRender event
 * @param {Object} event The render event object
 */
const moreonAdRenderCallback = (event) => {
	const { detail = {} } = event || {};

	if (!Object.keys(detail || {}).length) {
		return;
	}

	const ad = document.getElementById(`${detail.slot.getSlotElementId()}`);
	const wrapper = ad.closest('.fbs-ad--moreon-wrapper');

	if (wrapper) {
		wrapper.classList[isBlockedAd(detail.size) ? 'add' : 'remove']('blocked-ad');
	}
};

/**
 * Maps ad positions to their render callback
 */
const renderCallbackMap = {
	top: topAdRenderCallback,
	'spon-logo': sponLogoRenderCallback,
	mobile: mobileStickyRenderCallback,
	'mobile-sticky': mobileStickyRenderCallback,
	moreon: moreonAdRenderCallback,
};

/**
 * Adds render listener to ads that have render event callbacks
 * @param {FbsAd} ad
 * @param {string} position
 */
export function addRenderEventCallback(ad, position) {
	if (renderCallbackMap[position] && ad) {
		ad.addEventListener('render', renderCallbackMap[position]);
	}
}
